import { Buildings } from './buildings';
import { Tokens } from './tokens';

export const MINING_BUILDINGS = {
  [Tokens.ironOre]: {
    buildingName: '',
    buildingKey: Buildings.IronOreMine,
    buildingHash: '',
    buildingId: '',
    descriptionKey: 'iron_ore_mine_description_key'
  },
  [Tokens.coal]: {
    buildingName: '',
    buildingKey: Buildings.CoalMine,
    buildingHash: '',
    buildingId: '',
    descriptionKey: 'coal_Mine_description_key'
  },
  [Tokens.goldOre]: {
    buildingName: '',
    buildingKey: Buildings.GoldOreMine,
    buildingHash: '',
    buildingId: '',
    descriptionKey: 'gold_mine_description_key'
  },
  [Tokens.wood]: {
    buildingName: '',
    buildingKey: Buildings.Plantation,
    buildingHash: '',
    buildingId: '',
    descriptionKey: 'plantation_description_key'
  },
  [Tokens.clay]: {
    buildingName: '',
    buildingKey: Buildings.ClayQuarry,
    buildingHash: '',
    buildingId: '',
    descriptionKey: 'plantation_description_key'
  },
  [Tokens.sand]: {
    buildingName: '',
    buildingKey: Buildings.SandQuarry,
    buildingHash: '',
    buildingId: '',
    descriptionKey: 'plantation_description_key'
  }
};
